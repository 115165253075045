<template>
  <div class="bac">
    <el-card>
      <el-space>
        <div style="height: 3rem;width: 3rem">
          <el-upload ref="upload"
                     action="#"
                     class="avatar-uploader"
                     accept="image/png,image/gif,image/jpg,image/jpeg"
                     :show-file-list="false"
                     :limit=1
                     :auto-upload="false"
                     :on-change="imgChange">
            <img :src="projectInf.pic" alt="" v-show="projectInf.pic !=='' " style="width: 100%; ">
            <el-button type="primary">上传图片</el-button>
          </el-upload>

        </div>
        <div>
          <el-descriptions
              class="margin-top"
              :title="this.projectInf.projectName"
              :column="3"
              size="large"
              border
          >
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  公司名称
                </div>
              </template>
              <div>
                {{ this.projectInf.companyName }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  联系人姓名
                </div>
              </template>
              <div>
                {{ this.projectInf.linkman }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  联系人电话
                </div>
              </template>
              <div>
                {{ this.projectInf.phone }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  开始时间
                </div>
              </template>
              {{ this.projectInf.beginTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  截止时间
                </div>
              </template>
              {{ this.projectInf.endTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  需求类别
                </div>
              </template>
              {{ this.projectInf.typeSecond }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  项目周期
                </div>
              </template>
              {{ this.projectInf.period }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  目标人群
                </div>
              </template>
              {{ this.projectInf.targetPeople }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  风格要求
                </div>
              </template>
              {{ this.projectInf.style }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  预算金额
                </div>
              </template>
              {{ this.projectInf.budget }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  项目背景
                </div>
              </template>
              {{ this.projectInf.bacground }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  相关资料
                </div>
              </template>
              {{ this.projectInf.otherInformation }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-space>
      <el-button type="primary" @click="auditPost(1)">通过</el-button>
      <el-button type="danger" @click="auditPost(2)">拒绝</el-button>
    </el-card>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "ProjectDetail",
  data(){
    return{
      id:{
        pid:'',
      },
      projectInf:{},
      audit:{
        decide:'',
        uid:'',
        contentstyleId:'',
      }
    }
  },
  mounted() {
    this.getInf();
  },
  methods:{
    getInf(){
      this.id.pid = parseInt(this.$route.query.projectId);
      this.$http({
        method: 'post',
        url: '/projectMessage',
        data: this.id,
      }).then(res => {
        this.projectInf = res.data;
        this.audit.uid = res.data.uid;
        this.audit.contentstyleId = res.data.id;
      })
    },

    imgChange (files, fileList) {
      formData.append('file',fileList[0].raw)
      formData.append('pid',this.projectInf.id)


      this.$http({
        method: 'post',
        url: '/admin/updatePicForProject',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '操作成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }else {
          this.$notify({
            title: '操作失败',
            message: res.data.message,
            type: 'warning'
          });
        }
      })
    },

    auditPost(y){
      this.audit.decide = y;
      this.$http({
        method: 'post',
        url: '/admin/decidePublishProject',
        data: this.audit,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '操作成功',
            message: res.data.message,
            type: 'success'
          });
          this.$router.push('/AuditingProjects');
        }else {
          this.$notify({
            title: '操作失败',
            message: res.data.message,
            type: 'warning'
          });
        }
      })
    }

  }
}
</script>

<style scoped>

</style>